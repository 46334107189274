body{
  font-family: 'Roboto' !important;
}

.header-shape{
  width: 100%;
	height: 800px;
	background: linear-gradient(to right, #4fccdc, #2267c5 70%);
	position: absolute;
  -webkit-clip-path: ellipse(700px 700px at 10% 5%);
  clip-path: ellipse(700px 700px at 10% 5%);
  margin-top: -300px;
  z-index: -999;
}

p{
  font-size: 20px !important;
}
.navbar-custom-public{
  background:transparent;
  margin-top: 0;
}

.navbar-custom-public-nav>li>a{
  color: #4d4d4d !important;
  font-size: 22px;
}

.navbar-custom-public-nav>li:hover{
  border-bottom:5px solid #2267c5;
}

.navbar-active{
  border-bottom:5px solid rgba(255, 255, 255, 0.7);
}

.navbar-toggler-public{
  display: none;
}

.navbar-brand{
  font-size: 30px;
  font-weight: bold;
  color: #fff !important;
}

.primary-color{
  color: #2368C5;
}
.secondary-color{
  color: #4d4d4d !important;
}
.text-white{
  color: #fff !important;
}

.primary-bg{
  background: linear-gradient(to right, #4fccdc, #2267c5 70%);
}

.homepage-head{
  margin-bottom: 40px;
}
.homepage-head>.left>h1{
  font-size: 5vw;
  font-weight: bold;
  padding-top: 60px;
  color: #4d4d4d;
}

.homepage-head>.left>h2{
  font-size: 30px;
  color: #4d4d4d;
}

.homepage-title{
  font-size: 50px;
  font-weight: bold;
}

/*
.homepage-head>.right{
  background: url(/assets/images/bg_1.png) right bottom no-repeat;
  background-size: contain;
} */

.home-service-info {
  background: linear-gradient(to right, #4fccdc, #2267c5 70%);
  opacity: 0.8;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.home-service-info h3{
  color: #FFD600;
}
.home-service-info p{
  color: #fff;
  font-size: 15px !important;
}

.home-service-close-btn{
    background: transparent;
    border: 0;
}

.warta-section{
  margin-top: 20px;
  padding-top: 80px;
}

.warta-section > div {
  border-radius: 30px;
}

.warta-img-list{
  padding: 0;
  margin: 0;
}
.warta-img-list img{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.warta-overlay-title{
  text-align: center;
  color: #fff;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
.warta-overlay{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background:linear-gradient(to right, rgba(79, 204, 220, 0.9), rgba(34, 103, 197, 0.8) 70%);
	overflow: hidden;
	width: 0;
	height: 100%;
	transition: .5s ease;
}
.warta-img-list:hover .warta-overlay{
	width: 100%;
}

.white-bg-trasparent{
  background: rgba(255, 255, 255, 0.6)
}

.feature{
  background: rgba(206, 206, 206, 0.5);
  border-radius: 20px;
  border: 2px solid #fff;
  text-align: center;
  padding: 10px 0 10px 0;
  margin-bottom: 5px;
  cursor: pointer;
}

.feature:hover{
  background:linear-gradient(to right, rgba(79, 204, 220, 0.9), rgba(34, 103, 197, 0.8) 70%);
}

.siaga-section > div {
  padding-top: 40px;
}

.ibadah-section > .row{
  border-radius: 20px;
  background: rgba(0, 141, 143, 0.938);
  color: #fff;
  margin-top: 20px;
}
.ibadah-title{
  color: #FFD600;
}



.lokasi-terdekat-section{
  border-radius: 20px;
}
.pangan-section{
  border-radius: 20px;
  background:linear-gradient(to left, rgba(79, 204, 220, 0.9), transparent 70%);
}

.ispu-section{
  background: #2368C5;
  text-align: center;
  border-radius: 20px 0 20px 0;
  padding: 30px 10px 30px 10px;
}
.warkop-section{
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.warkop-title{
  font-size: 6vw;
  font-weight: bold;
  line-height: 0.8;
}

.quote-text{
  font-family: 'Pacifico', cursive;
}

.download-section {
  /* background:linear-gradient(to right, rgba(79, 204, 220, 0.9), rgba(34, 103, 197, 0.8) 70%);  */
  background:
    linear-gradient(to top left, rgba(34, 103, 197, 0.8) 50%, transparent 50.5%) no-repeat top,
    /* bottom part */
    linear-gradient(30deg, rgba(34, 103, 197, 0.8), rgba(34, 103, 197, 0.8)) no-repeat bottom;

  background-size: 100% 15em, 100% calc(100% - 15em);

}

.mockup2{
  margin-right: -200px;
}
.box {
  position: fixed;
  bottom: 0;
  transform: rotate(80deg);
  right: 0;
  z-index: -555;
  margin-bottom: -600px;
  margin-left: -300px;;
}

.wave {
  position: absolute;
  opacity: .1;
  width: 1000px;
  height: 1300px;
  border-radius: 43%;
}

.wave.-one {
  animation: rotate 7000ms infinite linear;
  opacity: .1;
  background: rgb(43, 53, 58);
}

.wave.-two {
  animation: rotate 3000ms infinite linear;
  opacity: .1;
  background: black;
}

.wave.-three {
  animation: rotate 7500ms infinite linear;
  background-color: #b3e3f5;
  opacity: .1;
}

.page-content{
  padding: 30px 10px 30px 10px;
  margin-top: 200px;
  background: rgba(204, 204, 204, 0.7);
  border-radius: 20px;
  font-size: 20px !important;
  text-align: justify;
}

.homepage-footer{
  margin-top: 30px;
  padding: 20px 0 20px 0;
  background:linear-gradient(to right, rgba(79, 204, 220, 0.9), rgba(34, 103, 197, 0.8) 70%);
}
/*
.wave {
  position: absolute;
  opacity: .4;
  width: 800px;
  height: 1300px;
  margin-left: -150px;
  margin-bottom: 0;
  border-radius: 43%;
}

.wave.-one {
  animation: rotate 7000ms infinite linear;
  opacity: .1;
  background: #0af;
}

.wave.-two {
  animation: rotate 3000ms infinite linear;
  opacity: .1;
  background: black;
}

.wave.-three {
  animation: rotate 7500ms infinite linear;
  background-color: #77daff;
} */

@keyframes rotate {
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
}


/*
.download-section{
  /* background:linear-gradient(to right, rgba(79, 204, 220, 0.9), rgba(34, 103, 197, 0.8) 70%);
}
*/

.home-footer{
  padding-top: 40px;
  background: transparent;
}

/* Animated  background square  */
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -5;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(35, 104, 197, 0.3);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 30%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 70%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 85%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}


.circles li:nth-child(6){
  left: 80%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}


@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .homepage-head>.left>h1{
    font-size: 50px;
    text-align: center;
  }
  .homepage-head>.left>h2{
    font-size: 20px;
    text-align: center;
  }
  .header-shape{
    -webkit-clip-path: ellipse(700px 700px at 10% 5%);
    clip-path: ellipse(300px 300px at 10% 5%);
    margin-top: -200px;
  }

  .navbar-toggler-public{
    display: block;
  }

  .download-app{
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .text-center-mobile{
    text-align: center;
  }

  .warkop-title{
    font-size: 15vw;
  }

}


.grecaptcha-badge {
  display:none !important;
}


.login-page .right{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0 10px 10px 0;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 100px !important;
}

.login-page .left{
  background: rgb(34,103,197);
  background: linear-gradient(90deg, rgba(34,103,197,1) 0%, rgba(79,204,220,1) 100%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px 0 0 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 100px !important;
}


.primary-color{
  color: #2368C5;
}

.btn.login-button{
  width: 100%;
  /* border: 1px solid #0D515E; */
  color: #fff;
  background: linear-gradient(90deg, rgba(34,103,197,1) 0%, rgba(79,204,220,1) 100%);
	background-position: right bottom;
	background-size: 200% 100%;
	-webkit-transition: 0.8s;
	transition: 0.8s;
}

.btn.login-button:hover{
  background-position: left bottom;
	color: #fff ;
}

.btn.btn-menu{
  color: #fff;
  border-radius: 50px;
  border: 1px solid #fff;
  background: linear-gradient(to right, #fff 50%, transparent 50%);
	background-position: right bottom;
	background-size: 200% 100%;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.btn.btn-menu:hover{
  background-position: left bottom;
  color: #2368C5;
  border: 1px solid #2368C5;
}

.flat-input{
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #cecece;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 96%, #0D515E 4%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #0D515E 4%);
	background-position: -500px 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.flat-input:focus{
	box-shadow: none;
	outline: none;
	background-position: 0 0;
	border-bottom: 0px;
}

.form-check-input{
  border: 1px solid #0D515E;
}
